import { apiSlice } from '@api/apiSlice';
import { API } from '@constants';
import { type Option } from '@shared/form/types';
import { catchCommonError } from '@utils/catchCommonError';
import { responseToSelectOptions } from '@utils/responseFormatters';

export const commonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getAllCampaigns',
      query: () => ({
        url: API.COMMON.CAMPAIGNS,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: Array<{ id: string; name: string }>) =>
        responseToSelectOptions<Array<{ id: string; name: string }>>(response, {
          key: 'name',
        }),
    }),

    getBrands: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getBrands',
      query: () => ({
        url: API.COMMON.BRANDS,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: Array<{ id: string; title: string }>) =>
        responseToSelectOptions<Array<{ id: string; title: string }>>(
          response,
          { key: 'title' }
        ),
    }),

    getAllBonusTemplates: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getAllBonusTemplates',
      query: () => ({
        url: API.COMMON.BONUS_TEMPLATES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: Array<{ id: string; name: string }>) =>
        responseToSelectOptions<Array<{ id: string; name: string }>>(response, {
          key: 'name',
        }),
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetCampaignsQuery,
  useGetAllBonusTemplatesQuery,
} = commonApiSlice;
