import { type KeyboardEvent } from 'react';

import { REGEX } from '@constants';

interface IPreventNonNumericTypingArgs {
  withDots?: boolean;
  allowEnter?: boolean;
  isZeroDisabled?: boolean;
}

const allowedKeys = [
  'Backspace',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Control',
  'Alt',
  'Shift',
  'Meta',
  'Escape',
  'Delete',
  'Home',
  'End',
  'PageUp',
  'PageDown',
];

const allowedShortcutCodes = ['KeyC', 'KeyV', 'KeyX', 'KeyA', 'KeyZ', 'KeyY'];

export const preventNonNumericTyping = (
  e: KeyboardEvent<HTMLDivElement>,
  {
    withDots = false,
    allowEnter = false,
    isZeroDisabled = false,
  }: IPreventNonNumericTypingArgs = {}
): void => {
  const currentValue = (e.target as HTMLInputElement).value;
  const { key, code } = e;

  if (allowEnter) {
    allowedKeys.push('Enter');
  }

  const isNumeric = REGEX.NUMERIC.test(key);
  const isAllowedChar = withDots ? REGEX.NUMERIC_WITH_DOT.test(key) : isNumeric;

  const isShortcutKeyPressed =
    (e.ctrlKey ||
      e.metaKey ||
      (e.ctrlKey && e.shiftKey) ||
      (e.metaKey && e.shiftKey)) &&
    allowedShortcutCodes.includes(e.code);

  const isAllowedKey = allowedKeys.includes(code) || isShortcutKeyPressed;

  if (!isAllowedChar && !isAllowedKey) {
    e.preventDefault();
    return;
  }

  if (
    withDots &&
    key === '.' &&
    (currentValue.includes('.') || currentValue === '')
  ) {
    e.preventDefault();
    return;
  }

  if (isZeroDisabled && currentValue.length === 0 && key === '0') {
    e.preventDefault();
  }
};
