interface IReplaceURLParamsOptions {
  asQueryParam?: boolean;
}

const paramRegex = /\/:[^/]+/g;

const replaceURLParams = (
  url: string,
  params?: Record<string, number | string>,
  options?: IReplaceURLParamsOptions
) => {
  const { asQueryParam = false } = options || {};

  if (!params || Object.keys(params).length === 0) {
    return url.replace(paramRegex, '');
  }

  if (asQueryParam) {
    const queryParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      queryParams.append(key, String(value));
    }
    url = url.replace(paramRegex, '');

    return `${url}?${queryParams.toString()}`;
  }

  const regexPattern = new RegExp(
    Object.keys(params)
      .map((key) => `:${key}`)
      .join('|'),
    'gi'
  );

  return url.replace(regexPattern, (matched) => {
    const key = matched.replace(':', '');
    const value = params[key];

    return String(value);
  });
};

export default replaceURLParams;
