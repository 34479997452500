import type { ClipboardEvent } from 'react';

interface IPreventPasteTextParams {
  allowFloat?: boolean;
}

export const onPreventPasteText = (
  e: ClipboardEvent<HTMLDivElement>,
  params: IPreventPasteTextParams = {}
) => {
  const { allowFloat = false } = params;
  const value = e.clipboardData.getData('text');
  const numericValue = Number(value);
  const isValueInvalid =
    isNaN(numericValue) || (!allowFloat && !Number.isInteger(numericValue));

  if (isValueInvalid) {
    e.preventDefault();
  }
};
