import type { Draft } from '@reduxjs/toolkit';

interface IErrorDetails {
  code: string;
  title: string;
  source: {
    fieldName: string;
    fieldValue: string;
    fieldPointer: string;
  };
}

export type MaybeDrafted<T> = T | Draft<T>;

export interface ICommonError {
  status: number;
  data: {
    type: string;
    title: string;
    detail: string;
    status: number;
    traceId: string;
    instance: string;
    timestamp: number;
    errors?: IErrorDetails[];
  };
}

export type IAdditionalPropertyFilters = Record<
  string,
  Record<string, unknown>
>;

export interface IBrand {
  id: number;
  title: string;
  domainName?: string;
}

export enum MODULE {
  AUTOMATIC_CAMPAIGN = 'automaticCampaign',
  BONUS = 'bonus',
  CAMPAIGN = 'campaign', // TODO: Remove after Orbit impl in BS-4097
  CAMPAIGN_ACTIVITY = 'campaignActivity',
  CAMPAIGN_MONITOR = 'campaignMonitor',
  CODE_CAMPAIGN = 'codeCampaign',
  PLAYER_BONUS = 'playerBonus',
  SCHEDULED_CAMPAIGN = 'scheduledCampaign',
}

export enum CAMPAIGN_TYPE {
  AUTOMATIC = 'AUTOMATIC',
  CODE = 'CODE',
  SCHEDULED = 'SCHEDULED',
}

export type Combinator = 'OR' | 'AND';

export enum IFRAME {
  ACTIVATE_CODE = 'ACTIVATE_CODE',
  CAMPAIGN_ACTIVITY = 'CAMPAIGN_ACTIVITY',
  CAMPAIGN_MONITOR = 'CAMPAIGN_MONITOR',
  GIVE_BONUS = 'GIVE_BONUS',
  PLAYER_BONUSES = 'PLAYER_BONUSES',
  PLAYER_CODES = 'PLAYER_CODES',
  PLAYER_OFFERS = 'PLAYER_OFFERS',
}

export enum POST_MESSAGE_EVENT_TYPE {
  CLOSE_ACTIVATE_CODE_MODAL = 'CLOSE_ACTIVATE_CODE_MODAL',
  CLOSE_GIVE_BONUS_MODAL = 'CLOSE_GIVE_BONUS_MODAL',
  COPY_EDIT_ID = 'copyEditId',
  CREDENTIALS_RECEIVED = 'CREDENTIALS_RECEIVED',
  ERROR_ACTIVATE_CODE_MODAL = 'ERROR_ACTIVATE_CODE_MODAL',
  ERROR_GIVE_BONUS_MODAL = 'ERROR_GIVE_BONUS_MODAL',
  OPEN_AUTOMATIC_CAMPAIGN_DETAILS = 'OPEN_AUTOMATIC_CAMPAIGN_DETAILS',
  OPEN_BONUS_TEMPLATE_DETAILS = 'OPEN_BONUS_TEMPLATE_DETAILS',
  OPEN_CODE_CAMPAIGN_DETAILS = 'OPEN_CODE_CAMPAIGN_DETAILS',
  OPEN_FREE_SPIN_DETAILS = 'OPEN_FREE_SPIN_DETAILS',
  OPEN_SCHEDULED_CAMPAIGN_DETAILS = 'OPEN_SCHEDULED_CAMPAIGN_DETAILS',
  SUCCESS_ACTIVATE_CODE_MODAL = 'SUCCESS_ACTIVATE_CODE_MODAL',
  SUCCESS_GIVE_BONUS_MODAL = 'SUCCESS_GIVE_BONUS_MODAL',
  WARNING_ACTIVATE_CODE_MODAL = 'WARNING_ACTIVATE_CODE_MODAL',
}
