export const CRYPTO_LIST = [
  'BTC',
  'XBT',
  'LTC',
  'NMC',
  'STC',
  'PPC',
  'DOGE',
  'XDG',
  'EMC',
  'GRC',
  'MSC',
  'XPM',
  'XRP',
  'AUR',
  'BC',
  'BURST',
  'DASH',
  'XDN',
  'MZC',
  'XMR',
  'XEM',
  'USDT',
  'NXT',
  'POT',
  'AMP',
  'TIT',
  'VTC',
  'ETH',
  'ETC',
  'IOT',
  'MIOTA',
  'SIL',
  'DCR',
  'WAVES',
  'LSK',
  'ZEC',
  'BCH',
  'BCC',
  'UBQ',
];
