function transformObjToSnakeCase(obj: unknown): unknown {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjToSnakeCase(item));
  }

  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`
      );
      (acc as Record<string, unknown>)[snakeKey] =
        transformObjToSnakeCase(value);
      return acc;
    }, {});
  }

  return obj;
}

export default transformObjToSnakeCase;
