import { apiSlice } from '@api/apiSlice';
import { API } from '@constants';
import { type IFreeSpinPayload } from '@modules/automaticCampaigns/types';
import { type IBonus } from '@modules/bonusManagement/types';
import { type Option } from '@shared/form/types';
import { responseToSelectOptions } from '@utils/responseFormatters';

export const freeSpinApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['free-spin-bonus-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFreeSpinBonusTemplates: builder.query<Option[], IFreeSpinPayload>({
        query: (payload) => ({
          params: payload,
          url: API.FREE_SPINS.ROOT,
        }),
        transformResponse: (response: IBonus[]) =>
          responseToSelectOptions<IBonus[]>(response, {
            key: 'title',
            withId: true,
          }),
      }),
    }),
  });

export const { useLazyGetFreeSpinBonusTemplatesQuery } = freeSpinApiSlice;
