import values from 'lodash/values';

import { ENV } from '@constants';

type EnabledFeatures = string[] | undefined;

export enum FEATURE_NAME {
  COPY_CODE_CAMPAIGN_LINK = 'copyCodeCampaignLink',
  OPEN_BONUS_TEMPLATE_DETAILS = 'openBonusTemplateDetails',
  OPEN_CAMPAIGN_DETAILS = 'openCampaignDetails',
}

export const getFeatureToggle = (feature: FEATURE_NAME): boolean => {
  const enabledFeatures =
    ENV.MODE === 'production'
      ? (ENV.VITE_FEATURE_ENABLED as EnabledFeatures)
      : values(FEATURE_NAME);

  return !!enabledFeatures && enabledFeatures.includes(feature);
};
