import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DATE } from '@constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

interface IFormatDateOptions {
  format?: string;
  timezone?: string;
  initialDateFormat?: string;
}

export const formattedDate = (
  date?: Date | null | number | string,
  options: IFormatDateOptions = {}
): string => {
  const [format, timezone, initialDateFormat] = [
    options.format ?? DATE.DATE_TIME_FULL_FORMAT,
    options.timezone ?? 'UTC',
    options.initialDateFormat,
  ];

  if (!date || !dayjs(date, initialDateFormat).isValid()) {
    return '';
  }

  return dayjs.utc(date, initialDateFormat).tz(timezone).format(format);
};

export const millisecondsToDays = (duration: number) =>
  dayjs.duration(duration, 'milliseconds').asDays();

export const daysToMilliseconds = (days: number) =>
  dayjs.duration(Number(days), 'day').asMilliseconds();
