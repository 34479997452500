import { toast } from 'react-toastify';

import {
  type BaseQueryFn,
  type FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { type RootState } from '@store';

import { CONFIG } from '@constants';
import { ENV, ROUTES } from '@constants';
import { POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

const { ACTIVATE_CODE, GIVE_BONUS_MANAGEMENT } = ROUTES;

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('X-FE-Version', CONFIG.APP_VERSION);

      const {
        auth: { token },
      } = getState() as RootState;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      if (
        ENV.VITE_BONUS_SERVICE_X_API_KEY &&
        baseUrl === ENV.VITE_BONUS_SERVICE_API_URL
      ) {
        headers.set('X-API-Key', ENV.VITE_BONUS_SERVICE_X_API_KEY);
      }

      return headers;
    },
  });

type BaseQueryCreator = (
  path: string
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const baseQueryCreator: BaseQueryCreator =
  (path: string) => async (args, api, extraOptions) => {
    const result = await baseQuery(path)(args, api, extraOptions);

    // Description: Handle all errors which does not cover by BE
    if (result?.error) {
      const errorData = result.error.data as Record<string, string>;
      const isIframe = window.self !== window.top;

      if (!errorData?.traceId) {
        if (
          isIframe &&
          window.location.pathname.includes(GIVE_BONUS_MANAGEMENT.ROOT)
        ) {
          sendPostMessage(
            POST_MESSAGE_EVENT_TYPE.ERROR_GIVE_BONUS_MODAL,
            errorData
          );
        }

        if (isIframe && window.location.pathname.includes(ACTIVATE_CODE.ROOT)) {
          sendPostMessage(
            POST_MESSAGE_EVENT_TYPE.ERROR_ACTIVATE_CODE_MODAL,
            errorData
          );
        }

        toast.error(
          `${String(result?.error?.status)}: ${
            errorData?.title ??
            errorData?.error ??
            errorData?.detail ??
            'Unknown issue'
          }`,
          { style: { whiteSpace: 'pre-wrap' } }
        );
      }
    }

    return result;
  };
