export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const DATE_TIME_FULL_FORMAT = 'DD-MM-YYYY, HH:mm:ss';
export const SCHEDULE_DATE_UI_FORMAT = 'ddd, DD-MM-YYYY HH:mm';
export const SCHEDULE_DATE_PAYLOAD_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DECISION_LOG_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss.SSS';

export const BONUS_GRANTING_TIME_FORMAT = 'HH:mm';
export const START_TIME_FORMAT = 'HH:mm:00';
export const END_TIME_FORMAT = 'HH:mm:59';
