export const handleSaveCsv = (data: {
  response: string;
  headers: Record<string, string>;
}) => {
  const downloadLink = document.createElement('a');
  const blob = new Blob([data.response], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  downloadLink.href = url;

  downloadLink.download = data.headers['content-disposition']
    .split('"')[1]
    .split('.')[0];

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};
