export const isValidTimezone = (timezone?: string) => {
  if (!timezone) {
    return false;
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });

    return true;
  } catch {
    return false;
  }
};
