import { type ICustomFile } from '@shared/form/FormFileUploader/types';

export function getDropzoneData(file: string | ICustomFile, index?: number) {
  if (typeof file === 'string') {
    return {
      preview: file,
      key: index ? `${file}-${index}` : file,
    };
  }

  return {
    type: file.type,
    path: file.path,
    size: file.size,
    name: file.name,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    key: index ? `${file.name}-${index}` : file.name,
  };
}
