import dayjs, { type Dayjs } from 'dayjs';

import { type Option } from '@shared/form/types';

type FilterFormData = Record<
  string,
  Partial<{
    operator?: string;
    inversion: boolean;
    fieldLabel?: string;
    value: null | Dayjs | number | Option | string | Option[] | undefined;
  }>
>;

export const formatDateTimeFilterValueToPayload = (data: FilterFormData) =>
  Object.keys(data).reduce((acc, key) => {
    if (data && dayjs.isDayjs(data[key]?.value as Dayjs)) {
      return {
        ...acc,
        [key]: {
          ...data[key],
          value: dayjs.utc(data[key]?.value as Dayjs)?.valueOf(),
        },
      };
    }
    if (data && Array.isArray(data[key]?.value)) {
      const filterValue = data[key]?.value;

      return {
        ...acc,
        [key]: {
          ...data[key],
          valueLabel: filterValue.map((item) => item.label),
        },
      };
    }

    return {
      ...acc,
      [key]: { ...data[key] },
    };
  }, {});
